.about-color-div{
    width:100;
    background-image: linear-gradient(#d8564e, #e77842) !important;
    height: 5rem;
}

.about-headline {
    color: #006199;
}

.about-copy {
    color: #0076A7;
    font-size: 1.1rem;
    line-height: 37px;
    opacity: 0.8; 
}

.col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.about__col-left {
    background-image: url('images/whats-hireed.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.about__col-right {
    background-image: url('images/whats-hireed-right.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .about__col-left {
        height: auto;
    }
    .about__col-right {
        height: 450px;
    }   
}
@media screen and (max-width: 575px) {
    .about__col-right {
        height: 300px;
    }
    .about__col-left.col-lg-7.col-sm-12.col-12 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
