.get-started {
    background-image: url('./images/get-started-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
}

.get-started__header {
    color: #0B3255;
}

.get-started__copy {
    color: #1B547F;
}

.get-started__col-header {
    color: #023962;
}

.get-started__col-copy {
    color: #00426F;
}

.get-started-image-wrapper {
    height: 11rem;
}