.card-data__front {
    color: #1E4A71;
    font-size: 1.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.cardTile {
    margin-right: 1rem;
}

.tile-stat-text{
    font-size: 1.2rem;
}

.plus-button__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.learn-more__wrapper {
    bottom: 3rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.btn-learn{
    background-color: #1E4A71 !important;
    color: #ffffff !important;
    font-size: .7rem !important;
}

.btn-group-sm>.btn, .btn-sm {
    padding: .5rem 1.6rem !important;
    margin-top: 1.5rem;
}

p.card-text {
    padding-left: 1rem;
    padding-right: 1rem;
}

.tile-plus{
    border: 0 !important; 
    padding-bottom: 0 !important;
}

.tile-reverse{
    background-image: linear-gradient(#d8564e, #e77842) !important;
    color: #FFFFFF;
    font-weight: 800;
}

.tile-stat-number{
    color: #1E4A71;
    font-size: 3rem;
}

.tile-reverse-title{
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.3rem;
}

.row.tile-stat-text.justify-content-center.word-wrap {
    font-size: 1.5rem;
}

.row.justify-content-center.tile-reverse-title {
    font-size: 1.3rem;
}

@media screen and (max-width: 900px) {
    .cardTile{
        height: 5rem !important;
        width: 20rem !important;
        color: #1E4A71 !important;
        justify-content: left !important;
        font-size: .75rem !important;
        margin-top: 1.2rem;
        margin-right: 0 !important;
    }

    .ml-8{
        margin-left: 7.4rem;
        visibility: collapse;
    }

    /* .card-body {
        padding: 0 !important;
    } */

    p.card-text {
        margin-bottom: 0 !important;
        position: relative;
        top: 1rem;
    }
    .learn-more__wrapper {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }
    .cardTile .card-body {
        padding: 0 !important;
    }
}