.jumbotron{
    margin-bottom: 0 !important;
}

.main-background{
    background-image: url("./images/main_background.png");
    margin-bottom: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem;
}

.home-text-banner {
    padding-top: 2rem;
    text-align: center;
}

.home-text-banner h2 {
    color: #006199;
}

.banner__copy {
    color: #0099CA;
}