.explore-background{
    background-color: #134068;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.explore__wrapper {
    position: relative;
}

.explore-tab {
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translateX(-50%);
}

.explore-dropdown-padding{
    padding: .25rem .25rem .75rem .75rem
}

.btn-explore{
    background-color: #FFCF3D !important;
}

.col-sm-6.col-xs-12.button__col--left {
    text-align: right;
}

@media screen and (max-width: 575px) {
    .col-sm-6.col-xs-12.button__col--left,
    .col-sm-6.col-xs-12.button__col--right {
        text-align: center !important;
    }

    .col-sm-6.col-xs-12.button__col--right {
        margin-top: 1rem;
    }
}

.dropdown {
    text-align:center;
}

.explore-dd-background{
    background-color: #134068 !important;
    color: #ffffff !important;
}

a.explore-dd-background.dropdown-item:hover {
    color: #F87839 !important;
}

@media only screen and (max-width: 767px) {
    .btn-explore{
        background-color: #FFCF3D !important;
        padding-top: .5rem;
        max-width: 15rem;
    }

    .explore-background{
        background-color: #1E4A71;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .row__button {
        margin-bottom: 1.5rem;
    }
    .explore-dd-background.dropdown-menu.show {
        transform: translate3d(-95px, 52px, 0px);
    }
  }