.navbar {
    background-color: transparent !important;
    position: absolute !important;
    z-index: 5 !important;
    width: 100% !important;
}

.navbar-light .navbar-toggler {
    position: absolute !important;
    right: 1rem !important;
}

a.nav-link {
    color: #FFFFFF !important;
}

button.navbar-toggler {
    border: none !important;
}

.navbar-toggler-icon {
    background-image: url('./images/Menu_Hamburger.png') !important;
}

@media screen and (max-width: 575px) {
    a.navbar-brand img {
        width: 75px;
    }
    .navbar-nav {
        /* display: flex; */
        position: fixed;
        top: 0rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-image: linear-gradient(#d8564e, #e77842) !important;
        text-align: center;
        z-index: 6;
    }
    button.navbar-toggler.collapsed {
        padding-top: 0;
        z-index: 7;
    }
}