.nav-background {
    background-image: url("./images/education-nav-background.jpg");
    margin-bottom: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
}

.education-background{
  background-image: url("./images/education-cluster-background.jpg");
  margin-bottom: 0 !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.tile-wrapper {
    max-width: 1000px;
    margin: 0 auto;
}

.get-started {
  text-align: center;
}

.about-logo {
    margin-right: 5px;
}

.career-text-banner {
    color: #006199;
}

.career-spotlight {
    background-image: url('./images/get-started-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
}

.career-hero-data {
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;
  text-align: center;
}

.hero-icon__wrapper.col-lg-3.col-md-12.col-sm-12.col-12 {
  text-align: center;
}

.hero-copy__headline {
  color: #0B3255;
}

.hero-copy__text {
  color: #0076A7;
  /* font-family: Roboto-Regular; */
  font-size: 1.1rem;
  line-height: 37px;
  opacity: 0.8;
}

.hero-headline {
  text-align: center;
}

.hero-data__headline {
  margin-bottom: 0;
}

.hero-data__salary {
  /* font-family: Roboto-Black; */
  font-size: 32px;
  color: #FFD900;
  letter-spacing: 0;
  text-align: center;
  line-height: 38px;
  text-shadow: 0 2px 9px rgba(0,0,0,0.50);
}

.career-cluster-text {
    color: #0076A7;
}

.military__buttons.mt-5.row {
    justify-content: space-between;
}

.btn-military {
    background-color: #1E4A71 !important;
    color: #FFFFFF !important;
    width: 6.6rem;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.btn-military:hover {
    background-image: linear-gradient(.25turn, #d8564e, #e77842);
}

@media screen and (max-width: 768px) {
  .hero-icon {
      padding-bottom: 1rem;
  }
  img.hero-icon.pr-5 {
      padding-right: 0 !important;
  } 
  .cardTile.text-center.card__full-height.card .card-body {
    padding: 0 !important;
  }
  .pt-5.pb-5.pl-5.col-md-8.col-sm-12.col-12 {
    padding: 0 !important;
}
.hero-icon__wrapper.col-lg-3.col-md-12.col-sm-12.col-12 {
    padding-top: 2rem;
}
}
