.cluster-card__title.card-header {
    padding: .5em 1.25em .5em 1.75em !important;
}

.cluster-tile-plus{
    padding: 0 0 !important;
    border: 0 !important;
}

.ml-8{
    margin-left: 7.4rem;
}

.cluster-tile-reverse{
    background-image: linear-gradient(#d8564e, #e77842) !important;
}

.tile-stat-number{
    color: #1E4A71;
    font-size: 3rem;
}

.tile-stat-text{
    font-size: 2rem;
}

.tile-reverse-title{
    font-size: .7rem
}

.btn-learn{
    background-color: #1E4A71 !important;
    color: #ffffff !important;
    font-size: .7rem !important;
}

.clusterCardTile{
    width: 30rem !important;
    color: #1E4A71 !important;
    justify-content: left !important;
    font-size: 17px !important;
    margin-top: .2rem;
}

.center-text{
    justify-content: center !important;
}

.cluster-row {
    align-items: center;
}

#cardContent {
    margin: 0 auto;
}

.card {
    margin-right: 1rem;
}

@media screen and (max-width: 768px) {
    #cardContent {
        width: 90%;
        margin: 0 auto;
    }
    .clusterCardTile {
        width: auto !important;
    }
    .cluster-tile__card-body.card-body {
        padding: .25rem !important;
    }
    .hero-copy.pl-5.pr-5.col-lg-9.col-md-12.col-sm-12.col-12 {
        padding: 2rem !important;
    }

}