.card__resource {
    height: auto;
}

.card__resource .card-body {
    padding: 2rem;
}

.cardTile.text-center.card__full-height.card {
    margin-top: 2rem;
}

.access__headline {
    color: #1E4A71;
}

.access__copy {
    color: #0099CA
}

.btn-more{
    background-color: #1E4A71 !important;
    color:#FFFFFF !important;
}

.btn-view{
    border: 2px solid #e97451 !important;
    border-radius: .4rem !important;
    color: #e97451 !important;
    font-weight: 600 !important;
    width: 9rem;
}

.resource-card-image{
    width: 100%;
    height: 9rem;
}

.resource-text{
    font-size: 12px;
}

.resource-title {
    color: #F67051;
    font-size: 1.1rem;
}

.resource-copy {
    color: #0B3255;
}

.resource-cta {
    color: #1B547F;
    font-weight: 600;
}

.native-padding{
    padding: .8rem .8rem;
}

.btn-more {
    width: 8.5rem;
    padding: .7rem 1rem !important;
}

.btn-more:hover {
    background-image: linear-gradient(.25turn, #d8564e, #e77842);
}

@media screen and (max-width: 900px) {
    .card__resource.card__full-height {
        margin-left: 0 !important;
        margin-top: 1rem;
        margin-right: 1rem;
    }

    .resource-card--home {
        position: relative !important;
        top: 0 !important;
    }

    p.resource-text.resource-card--home.card-text {
        margin-bottom: 2rem !important;
    }
}