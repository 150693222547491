.hero-data {
    background-image: url('./images/high-school-right.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFFFFF;
    text-align: center;
}

.hero-icon__wrapper.col-lg-3.col-md-12.col-sm-12.col-12 {
    text-align: center;
}

.hero-copy__headline {
    color: #0B3255;
}

.hero-copy__text {
    color: #0076A7;
    /* font-family: Roboto-Regular; */
    font-size: 1.1rem;
    line-height: 37px;
    opacity: 0.8;
}

.hero-headline {
    text-align: center;
}

.hero-data__headline {
    margin-bottom: 0;
}

.hero-data__salary {
    /* font-family: Roboto-Black; */
    font-size: 80px;
    color: #FFD900;
    letter-spacing: 0;
    text-align: center;
    line-height: 38px;
    text-shadow: 0 2px 9px rgba(0,0,0,0.50);
}

@media screen and (max-width: 768px) {
    .hero-icon {
        padding-bottom: 1rem;
    }
    img.hero-icon.pr-5 {
        padding-right: 0 !important;
    }  
}



