.footer-background{
    background-color: #1E4A71;
    height: 13rem;
}

.btn-back{
    background-color: #FFCF3D !important;
    color: #1E4A71 !important;
}

.footer-divider{
    border: 1px solid gray;
}

.footer-link-nav{
    color: #ffffff !important;
}

.back-to-top {
    text-align: right;
}

.copyright {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .btn-back {
        background-color: #ffcf3d!important;
        color: #1e4a71!important;
        float: none !important;
        margin-top: 1rem;
    }
    .footer-background {
        height: auto;
    }
    a.nav-link.footer-link-nav.header-link-text {
        padding-right: 0;
        padding-left: 0;
    }
    .back-to-top {
        text-align: center;
    }
    .copyright {
        text-align: center;
    }
}