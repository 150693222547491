.cluster-card__title.card-header {
    padding: .5em 1.25em .5em 1.75em !important;
}

.cluster-tile-plus{
    padding: 0 0 !important;
    border: 0 !important;
}

.ml-8{
    margin-left: 7.4rem;
}

.cluster-tile-reverse{
    background-image: linear-gradient(#d8564e, #e77842) !important;
}

.tile-stat-number{
    color: #1E4A71;
    font-size: 3rem;
}

.tile-stat-text{
    font-size: 2rem;
}

.tile-reverse-title{
    font-size: .7rem
}

.btn-learn{
    background-color: #1E4A71 !important;
    color: #ffffff !important;
    font-size: .7rem !important;
}

.clusterCardTile{
    width: 30rem !important;
    color: #1E4A71 !important;
    justify-content: left !important;
    font-size: 17px !important;
    margin-top: .2rem;
}

.center-text{
    justify-content: center !important;
}

.cluster-row {
    align-items: center;
}

#cardContent {
    margin: 0 auto;
}

.tile__job-title,
.tile__job-salary,
.tile__job-openings {
    color: #1B547F;
    font-size: 1rem;
}

.tile__job-salary {
    color: #1B547F;
    font-weight: 800;
}
.tile__job-openings {
    color: #1B547F;
    font-weight: 800;
}
.cluster-tile__column.pr-2.pt-3.col-sm-5 {
    padding-left: 0;
}

.cluster-tile__card-body.card-body {
    padding: 1.25rem !important;
}

.cluster-tile__header {
    color: #EA5747;
    font-weight: 600;
    font-size: 1rem;
}

.tile-separator {
    border-bottom: 1px solid #3F698D;
}