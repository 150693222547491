body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-view:hover {
    background-image: linear-gradient(.25turn, #d8564e, #e77842);
    color: #FFFFFF !important;
}

.card__full-height {
    height: 409px;
    width: 291px;
}

.wrapper {
    padding: 1rem;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}